const colors = {
  black1: '#151320',
  black2: '#22212c',
  grey: '#999999',
  white: '#f2f2f2',
  mint: '#8aff80',
  purple: '#7e64ff',
};

module.exports = { colors };
